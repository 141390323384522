<template>
    <div class="base">
        <input type="file" @change="change" ref="file" style="display: none" accept=".png,.jpg">
        <div>
            <div @click="addImage" class="add-thumb-img" v-if="value">
                <span @click.stop="removeImg" class="remove"><i class="el-icon-remove"></i></span>
                <img :src="value" alt="">
            </div>
            <div @click="addImage" class="add-thumb-img" v-else>
                <p><i class="el-icon-plus"></i></p>
                <p class="add-img">添加封面</p>
            </div>
        </div>
        <div class="img-select">
            <p>
                <el-button icon="el-icon-refresh" size="mini">刷新</el-button>
            </p>
            <div class="img-list">
                <div class="item">
                    <img :src="item.src" v-for="item in images" :key="item.src" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            baseUrl: '/bbs/upload/image',
            value: '',
            images: [],
        }
    },
    methods: {
        getValue() {
            return this.value;
        },
        setValue(value) {
            this.value=value;
            //些方法主要是使用在父组件设置值的时候使用
        },
        setImages(images) {
            if (Array.isArray(images)) {
                this.images.splice(0, 1000, ...images);
            }
        },
        change() {
            let file = this.$refs['file'].files[0];
            if (!file) return;
            let extension = file.name.split('.').pop().toLowerCase();
            if (['jpg', 'png'].indexOf(extension) === -1) {
                this.$message.warning('图片格式错误');
                return;
            }
            let formData = new FormData();
            formData.append('image', file);
            this.$refs.file.value = '';
            this.$bbs.post(this.baseUrl, formData).then(data => {
                this.value = data.data.src;
            });
        },
        removeImg() {
            this.$confirm('你确认要取消封面图吗?', '操作确认', {
                type: 'warning'
            }).then(() => {
                this.value = '';
            });
        },
        addImage() {
            this.$refs['file'].click();
        }
    },
}

</script>
<style scoped lang="less">
.base {
    display: flex;
    width: 100%;
    align-items: center;
}

.add-thumb-img {
    width: 160px;
    height: 110px;
    display: flex;
    color: #fff;
    flex-shrink: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid #999;
    background-color: #ccc;
    position: relative;

    .remove {
        position: absolute;
        top: 5px;
        right: 8px;
        color: red;
        z-index: 9;
        cursor: pointer;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.img-select {
    flex: 1;
    padding: 10px;

    .img-list {
        width: 100%;
        height: 80px;
        border: 1px solid #999;
        background-color: #ccc;
    }
}

</style>
