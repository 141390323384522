<template>
    <el-dialog
        :title="$store.state.question.title"
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :visible.sync="display"
        width="950px"
        :before-close="handleClose">
        <div>
            <el-input type="hidden" v-model="answer.answer_id"></el-input>
            <div class="item">
                <el-input v-model="answer.title" placeholder="文章标题，长度5~100"></el-input>
            </div>
            <!--            <div class="item">-->
            <!--                <el-radio-group v-model="answer.type_id">-->
            <!--                    <el-radio :label="item.value"  :key="item.value" v-for="item in $store.state.dict.answer_type">{{item.label}}</el-radio>-->
            <!--                </el-radio-group>-->
            <!--            </div>-->
            <div class="item">
                <div style="width: 100%">
                    <Toolbar
                        ref="toolbar"
                        class="toolbar"
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                    />
                    <!-- 编辑器 -->
                    <Editor
                        class="editor"
                        :defaultConfig="editorConfig"
                        v-model="answer.content"
                        @onCreated="editorCreated"
                    />
                </div>
            </div>
            <div class="item">
                <div class="title">
                    添加封面:
                </div>
                <div class="right-body">
                    <feng-mian ref="thumb"></feng-mian>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="$store.state.loading" @click="addAnswer">确 定</el-button>
  </span>
    </el-dialog>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css';
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import FengMian from "@/components/common/feng_mian.vue";
import wenda from "@/components/wenda/index.vue";
import tools, {
    extractImageDataFromRtf,
    findAllImgSrcsFromHtml,
    replaceImagesFileSourceWithInlineRepresentation
} from "@/utils/tools";

export default {
    props: {
        show: {
            default: true,
        },
        placeholder: {
            type: String,
            default: '',
        }
    },
    watch: {
        '$store.state.question.show': function () {
            this.display = true;
            this.answer = this.$store.state.question.data;
            // this.answer_id=this.$store.state.question.data.answer_id||'';
            // this.title=this.$store.state.question.data.title||'';
            // this.content=this.$store.state.question.data.content||'';
        }
    },
    components: {
        Editor, Toolbar, FengMian
    },
    data() {
        return {
            editor: null,
            title: '',
            answer: {
                answer_id: '',
                title: '',
                type_id: '',
                content: '',
                thumb: '',
            },
            display: false,
            baseUrl: '/bbs/answer/index',
            toolbarConfig: {
                toolbarKeys: ["headerSelect", 'uploadImage', "color", "bgColor", "|", "fontSize", "lineHeight", "|", {
                    "key": "group-justify",
                    "title": "对齐",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                    "menuKeys": ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify"]
                }, {
                    "key": "group-indent",
                    "title": "缩进",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                    "menuKeys": ["indent", "delIndent"]
                }, "|", "emotion", "insertLink",
                    "insertTable", "codeBlock", "divider", "|", "undo", "redo", "|", "fullScreen"]
            },
            editorConfig: {
                placeholder: '请输入内容',
                MENU_CONF: {
                    uploadImage: {
                        customUpload: async (file, insertFn) => {
                            let formData = new FormData();
                            formData.append('image', file);
                            let data = await this.$bbs.post('/bbs/upload/image', formData).catch(() => {

                            });
                            if (!data) {
                                this.$message.warning('图片上传失败!');
                                return;
                            }
                            insertFn(data.data.src);
                        }
                    }
                }
            },
        }
    },

    methods: {
        customPaste: (editor, event) => {
            // 获取粘贴的html部分（？？没错粘贴word时候，一部分内容就是html），该部分包含了图片img标签
            let html = event.clipboardData.getData('text/html');
            // 获取rtf数据（从word、wps复制粘贴时有），复制粘贴过程中图片的数据就保存在rtf中
            const rtf = event.clipboardData.getData('text/rtf');
            if (html && rtf) { // 该条件分支即表示要自定义word粘贴
                // 列表缩进会超出边框，直接过滤掉
                html = html.replace(/text\-indent:\-(.*?)pt/gi, '');
                // 从html内容中查找粘贴内容中是否有图片元素，并返回img标签的属性src值的集合
                const imgSrcs = findAllImgSrcsFromHtml(html);
                console.log(imgSrcs);
                // 如果有
                if (imgSrcs && Array.isArray(imgSrcs) && imgSrcs.length) {
                    // 从rtf内容中查找图片数据
                    const rtfImageData = extractImageDataFromRtf(rtf);
                    // 如果找到
                    if (rtfImageData.length) {
                        // TODO：此处可以将图片上传到自己的服务器上
                        // 执行替换：将html内容中的img标签的src替换成ref中的图片数据，如果上面上传了则为图片路径
                        html = replaceImagesFileSourceWithInlineRepresentation(html, imgSrcs, rtfImageData);

                        editor.dangerouslyInsertHtml(html);
                    }
                }
                // 阻止默认的粘贴行为
                event.preventDefault();
                return false;
            } else {
                return true;
            }
        },
        handleClose() {
            this.$confirm('你确认要取消吗?', '操作确认', {
                type: 'warning'
            }).then(() => {
                this.display = false;
            })
        },
        editorCreated(editor) {
            this.editor = Object.seal(editor);
            console.log(this.answer);
            this.$refs['thumb'].setValue(this.answer.thumb);
        },
        addAnswer() {
            this.answer.thumb = this.$refs['thumb'].getValue();
            if (this.answer.answer_id) {
                //如果是修改问题
                this.$bbs.put(this.baseUrl, this.answer).then((answer) => {
                    this.$message.success('修改成功!');
                    this.display = false;
                    //这里触发一个事件;
                    this.$store.commit('editAnswer', {data: answer.data, edit: Math.random()});
                    // this.$router.push({
                    //     path: '/wenda/detail/'+answer.answer_id+'.html',
                    // });
                });
            } else {
                this.$bbs.post(this.baseUrl, this.answer, {need: {error: true}}).then((answer) => {
                    this.$message.success('添加成功!');
                    this.display = false;
                    this.$router.push({
                        path: '/wenda/detail/' + answer.data.answer_id + '.html',
                    });
                });
            }
        },
        closeDialog: function () {

        }
    }
}
</script>
<style scoped lang="less">
.item {
    margin-bottom: 15px;
    display: flex;
    padding: 5px 0;

    .title {
        width: 80px;
        flex-shrink: 0;
    }

    .right-body {
        flex: 1;
    }
}

.toolbar {
    border-bottom: 1px solid #eee;
}

.editor {
    height: 330px;
    border: 1px solid #eee;
    border-top: none;
}
</style>
