<template>
    <div>
        <div class="input-item">
            <input placeholder="用户名/手机号" type="text" v-model="form.username">
        </div>
        <div class="input-item">
            <input placeholder="请输入密码" type="password" v-model="form.password">
        </div>
        <div class="btn-area">
            <el-button class="login-btn" @click="submit" type="primary">
                <span class="w">登</span> <span class="w">录</span>
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                username: '',
                password: '',
            }
        }
    },
    methods:{
        submit(){

        }
    }
}
</script>
<style scoped lang="less">

.input-item {
    padding: 30px 0;
    position: relative;

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #e3e2e2;
    }

    .label {
        padding: 10px 0;
    }

    input {
        font-size: 16px;
        border: none;
        display: inline-block;
        width: 100%;
        padding: 5px 0;
        letter-spacing: 1px;
        border-radius: 4px;

        &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px white inset;
            -webkit-box-shadow: 0 0 0px 1000px white inset;
            -webkit-text-fill-color: #333;
        }

        &[type=password] {
            letter-spacing: 2px;

        }

        &::placeholder {
            font-size: 14px;
        }

        &:focus {
            outline: none;
        }
    }
}
.login-btn {
    //letter-spacing: 50px;
    display: block;
    width: 100%;
    padding: 12px 0;
    font-size: 18px;

    .w {
        padding: 0 25px;
    }
}
</style>
