<template>
    <div>
        <div class="wx-img">
            <div class="angle"></div>
            <div class="angle bottom"></div>
            <div class="scan">

            </div>
            <div class="scan-img">
                <img draggable="false" :src="login.codeUrl" alt="">
            </div>
        </div>
        <div class="wx-tip text-center">
            打开微信扫一扫，快速登录/注册
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            checkUrl: '/bbs/api/scan',
            login: {
                codeUrl: '',
                uuid: '',
                currentTime: 0,
                scene: '',
            }
        }
    },
    mounted() {
        //不能在这里执行getQrcodeUrl 方法，因为默认情况只会走一次，这个方法
    },
    methods: {
        getQrcodeUrl() {
            this.login.scene = 'bbs_' + Math.random();
            this.login.uuid = 'bbs_uuid_' + Math.random();
            this.login.currentTime = new Date().getTime();
            this.login.codeUrl = 'http://api.bigemap.com/app/office/qrcode?uuid=uuid&scene=' + this.login.scene;
            this.checkLogin();
        },
        checkLogin() {
            if (new Date().getTime() - this.login.currentTime > 1000 * 60 * 3) {
                //默认在3秒之后才开始进行是不是已经扫码的验证
                return;
            }
            clearTimeout(this.login.checkTimer);
            this.login.checkTimer = setTimeout(() => {
                this.$bbs.post(this.checkUrl, {
                    scene: this.login.scene,
                    uuid: this.login.uuid,
                }, {elseConfig: {showError: false}}).then((data) => {
                    this.$emit('loginChange',{loginData:data});
                }).catch(() => {
                    this.checkLogin();
                });
            }, 2000);
        },
        //在父组件关闭登录窗口的时候，要停止定时器
        stopScan() {
            clearTimeout(this.login.checkTimer);
        },
    },
    beforeDestroy() {
        this.stopScan();
    }
}
</script>
<style scoped lang="less">
.wx-img {
    padding: 15px;
    overflow: hidden;
    width: 260px;
    margin: 10px auto;
    height: 260px;
    border: 1px solid #eee;
    position: relative;

    .angle {
        position: absolute;
        top: 0;
        bottom: 0;

        &.bottom {
            right: 0;
            top: 0;
            bottom: 0;

            &::before {
                position: absolute;
                display: block;
                content: '';
                width: 30px;
                height: 30px;
                left: -30px;
                top: 0;
                border: 2px solid transparent;
                border-right-color: #cccccc;
                border-top-color: #cccccc;
            }

            &::after {
                position: absolute;
                display: block;
                content: '';
                width: 30px;
                height: 30px;
                left: -30px;
                bottom: 0;
                border: 2px solid transparent;
                border-right-color: #cccccc;
                border-bottom-color: #cccccc;
            }
        }

        &::before {
            position: absolute;
            display: block;
            content: '';
            width: 30px;
            height: 30px;
            //right: -15px;
            left: -15px;
            top: 0;
            border: 2px solid transparent;
            border-left-color: #cccccc;
            border-top-color: #ccc;
        }

        &::after {
            position: absolute;
            display: block;
            content: '';
            width: 30px;
            height: 30px;
            left: -15px;
            bottom: 0;
            border: 2px solid transparent;
            //border-color: transparent;
            border-left-color: #ccc;
            border-bottom-color: #ccc;
        }

    }

    .scan {
        left: 0;
        width: 100%;
        height: 25px;
        z-index: 99;
        border-bottom: 1px solid rgba(204, 143, 52, 0.5);
        position: absolute;
        background-image: linear-gradient(180deg, rgba(0, 255, 51, 0) 10%, rgba(204, 138, 52, 0.2) 100%);
        top: -25px;
        animation: move 3s linear infinite;

    }

    .scan-img {
        text-align: center;
        width: 100%;
        height: 100%;

        img {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

@keyframes move {
    0% {
        top: -25px
    }
    100% {
        top: 270px;
    }
}
</style>
